import React, { useEffect, useState } from 'react'
import Select from 'react-select'

type AlerteVehiculeType = "alerteVehiculeVN" | "alerteVehiculeVO" | "alerteVehiculeVDL"

interface OrigineOption
{
    label: string
    value: number
}

interface Origine
{
    IDOrigineAction: number
    Famille_Libelle: string
    SousFamille_Libelle: string
    Libelle: string
    Type_Description: string
}

interface OrigineAlerteVehiculeResponse
{
    IDOrigineActionDefault: number
    IDOrigineAction: number | null
    Contexte: AlerteVehiculeType
}

export default function List() {
    const [ origineOptions, setOrigineOptions ] = useState<OrigineOption[]>([])
    const [ alerteVehiculeOrigine, setAlerteVehiculeOrigine ] = useState<Record<AlerteVehiculeType, number | null>>({
        alerteVehiculeVN: null,
        alerteVehiculeVO: null,
        alerteVehiculeVDL: null,
    })
    const [ isSavingOrigineDefault, setIsSavingOrigineDefault ] = useState<boolean>(false)
    const [ isFetchingOrigineDefault, setIsFetchingOrigineDefault ] = useState<boolean>(true)

    const fetchOrigines = async () => {
        return await fetch(localStorage.backoffice_api + '/api/origines', {
            method: 'GET',
            headers: {
                authorization: localStorage.JWT,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText)
                }
                return response.json()
            })
            .then((json: Origine[]) => {
                const transformedOrigineArray = json.map(item => ({
                    label: `${item.Famille_Libelle} / ${item.SousFamille_Libelle} / ${item.Libelle} (${item.Type_Description})`,
                    value: item.IDOrigineAction,
                }))

                setOrigineOptions(transformedOrigineArray)
                return transformedOrigineArray
            })
            .catch((error) => {
                console.error(error)
                return []
            })
    }

    const fetchAlerteVehiculeOrigineDefault = async (origines: OrigineOption[]) => {
        try {
            const response = await fetch(localStorage.backoffice_api + '/api/origineDefault/alerteVehicule', {
                method: "GET",
                headers: { authorization: localStorage.JWT },
            })

            if (!response.ok) throw new Error(response.statusText)

            const json: OrigineAlerteVehiculeResponse[] = await response.json()

            // Création d'un nouvel objet d'état pour éviter plusieurs re-renders
            const newState = { ...alerteVehiculeOrigine }

            json.forEach((alerte) => {
                const foundOrigine = origines.find((opt) => opt.value === alerte.IDOrigineAction)
                if (foundOrigine) {
                    newState[alerte.Contexte] = foundOrigine.value
                }
            })

            setAlerteVehiculeOrigine(newState)
        } catch (error) {
            console.error(error)
        }
    }

    const handleAlerteVehiculeSelect = (type: AlerteVehiculeType, value: number) => {
        setAlerteVehiculeOrigine((prev) => ({
            ...prev,
            [type]: value,
        }))
    }

    const getAlerteVehiculeSelectValue = (type: AlerteVehiculeType) => {
        return origineOptions.find((option) => option.value === alerteVehiculeOrigine[type])
    }

    const saveAlerteVehiculeOrigine = () => {
        fetch(localStorage.backoffice_api + '/api/origineDefault/alerteVehicule', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                authorization: localStorage.JWT,
            },
            body: JSON.stringify(alerteVehiculeOrigine), // Utilisation directe du state
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => setIsSavingOrigineDefault(false))
    }

    useEffect(() => {
        const fetchData = (async () => {
            const origines = await fetchOrigines()
            await fetchAlerteVehiculeOrigineDefault(origines)
            setIsFetchingOrigineDefault(false)
        })

        fetchData()
    }, [])

    return (
        <div className="container-fluid">
            <div className="col">
                <div className="d-flex flex-row align-items-center" style={{ gap: "10px" }}>
                    <a href="/origines" className="text-muted" style={{ fontSize: "1.2em" }}>
                        <i className="fa-solid fa-arrow-left"></i>
                    </a>
                    <h2>Origines par défaut</h2>
                </div>

                <div className="d-flex flex-column mb-2 mt-4" style={{ gap: "3px" }}>
                    <div className="d-flex flex-column" style={{ gap: "3px" }}>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div>
                                <h4>Alertes véhicule</h4>
                                <span className="text-muted" style={{ fontSize: "0.9em" }}>
                                    Permet le paramétrage du type par défaut du lead créé lors d'une alerte véhicule
                                </span>
                            </div>
                            <div className="d-flex flex-row" style={{ gap: "20px" }}>
                                <button className="btn btn-primary" onClick={() => {
                                    setIsSavingOrigineDefault(true)
                                    saveAlerteVehiculeOrigine()
                                }}>
                                    {isSavingOrigineDefault ?
                                        <i className="fa fa-spinner fa-spin"></i>
                                        :
                                        <i className="fa-solid fa-floppy-disk"></i>
                                    }
                                </button>
                            </div>
                        </div>
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th>Type</th>
                                <th>Origine</th>
                            </tr>
                            </thead>
                            <tbody>
                            {([ "alerteVehiculeVN", "alerteVehiculeVO", "alerteVehiculeVDL" ] as AlerteVehiculeType[]).map((type) => (
                                <tr key={type}>
                                    <td>{type.replace("alerteVehicule", "")}</td>
                                    <td>
                                        {!isFetchingOrigineDefault ? <Select
                                                options={origineOptions}
                                                multi={false}
                                                value={getAlerteVehiculeSelectValue(type)}
                                                onChange={(option: OrigineOption) => handleAlerteVehiculeSelect(type, option.value)}
                                            />
                                            :
                                            <i className="fa fa-spinner fa-spin"></i>
                                        }
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
