import React, { Component } from 'react';
import ModalAddOrigine from './ModalAddOrigine';
import ModalDeleteOrigine from './ModalDeleteOrigine';
import ModalTransferOrigine from './ModalTransferOrigine';
import {CSVLink} from 'react-csv';
import Utils from '../../Utils';

var lodash = require('lodash')

class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      origines: [],
      allOrigines: [],
      familles: [],
      sousFamilles: [],
      updateInProgress: [],

      editOrigineLibelle: [],
      editOrigineLibelleContent: [],

      editFamilleLibelle:[],
      editFamilleLibelleContent: [],

      editSousFamilleLibelle:[],
      editSousFamilleLibelleContent: [],

      filtres:[],
      target:'',

      csvData: null,
      gpParams: null,
    }

    this.deleteEnabled = false;
  }

  componentWillMount() {
    this.fetchOrigines();
    this.fetchGpParams();
  }

  componentDidMount() {
    this.generateTooltips();
  }

  fetchOrigines() {
    fetch(localStorage.backoffice_api + '/api/origines', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {

      var familles = lodash.map(json, (o) => {
        return {
          IDFamilleOrigineContact: o.IDFamilleOrigineContact,
          Libelle: o.Famille_Libelle
        };
      })
      familles = lodash.uniqBy(familles, (f) => {
        return f.IDFamilleOrigineContact;
      })
      familles = lodash.sortBy(familles, (f) => {
        return f.Libelle;
      })
      var sousFamilles = lodash.map(json, (o) => {
        return {
          IDSousFamilleOrigineAction: o.IDSousFamilleOrigineAction,
          Libelle: o.SousFamille_Libelle
        };
      })
      sousFamilles = lodash.uniqBy(sousFamilles, (f) => {
        return f.IDSousFamilleOrigineAction;
      })
      sousFamilles = lodash.sortBy(sousFamilles, 'Libelle');

      json = lodash.sortBy(json, (o) => {
        return o.Famille_Libelle + ' - ' + o.IDFamilleOrigineContact + ' - ' + o.SousFamille_Libelle + ' - ' + o.SousFamille_Libelle + ' - ' + o.Libelle;
      })

      this.setState({
        origines: json,
        allOrigines: json,
        familles: familles,
        sousFamilles: sousFamilles
      },
        () => {
          this.generateTooltips()
          this.generateCSVData()
        }
      )
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchGpParams() {
    fetch(localStorage.backoffice_api + '/api/gpParametres', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        gpParams: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  generateCSVData(){

    var datas = [];
    datas.push(['Famille', 'Sous-famille', 'Origine', 'Type', 'Est Lead', 'Etat', 'Appel sortant', 'Appel entrant'])

    this.state.allOrigines.map((origine) => {
      datas.push([
        origine.Famille_Libelle,
        origine.SousFamille_Libelle,
        origine.Libelle,
        origine.Type_Description ? origine.Type_Description : 'Aucun',
        origine.EstLead ? 'Oui' : 'Non',
        origine.EstInactif ? 'Inactif' : 'Actif',
        origine.EstAppelSortant ? 'Oui' : 'Non',
        origine.EstAppelEntrant ? 'Oui' : 'Non'
      ]);
    })

    this.setState({
      csvData: datas,
    });

  }

  getCountSousFamille(IDFamilleOrigineContact) {
    var filter = lodash.filter(this.state.origines, (o) => {
      return o.IDFamilleOrigineContact === IDFamilleOrigineContact;
    })
    return filter.length;
  }

  getCountOrigine(IDSousFamilleOrigineAction) {
    var filter = lodash.filter(this.state.origines, (o) => {
      return o.IDSousFamilleOrigineAction === IDSousFamilleOrigineAction;
    })
    return filter.length;
  }

  onChangeCheckbox(origine, event) {

    var updateInProgress = this.state.updateInProgress;
    updateInProgress.push(origine.IDOrigineAction);

    this.setState({
      updateInProgress: updateInProgress,
      target:event.target.name,
    })

    origine[event.target.name] = event.target.checked;

    fetch(localStorage.backoffice_api + '/api/origines/'+origine.IDOrigineAction, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(origine)
    })
    .then((response) => {

      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });

        this.setState({
          updateInProgress: updateInProgress,
        })
        throw Error(response);
      }
      return response.json();
    })
    .then((json) => {
      let answer=json;

      if(answer.check ==false){
        origine[this.state.target] = false;
        alert(answer.message)
      }

      var updateInProgress = this.state.updateInProgress;
      lodash.remove(this.state.updateInProgress, (id) => {
        return id === origine.IDOrigineAction;
      });

      var indexOrigine = lodash.findIndex(this.state.origines, (o) => {
        return o.IDOrigineAction === origine.IDOrigineAction;
      })

      var origines = this.state.origines;
      origines[indexOrigine] = origine;

      this.setState({
        origines: origines,
        updateInProgress: updateInProgress,
      })

    })
    .catch((error) => {
      console.log(error)
    });
  }

  _handleDoubleClickItem(origine, event) {

    if (this.state.editOrigineLibelle.indexOf(origine.IDOrigineAction) > -1) { // édition déjà en cours
      return null;
    }

    var editOrigineLibelle = [];
    editOrigineLibelle.push(origine.IDOrigineAction);

    this.setState({
      editOrigineLibelle: editOrigineLibelle,
    }, () => {
      this.refs.refEditOrigineLibelle.focus();
    })
  }

  _handleDoubleClickItemFamille(origine, event) {

    if (this.state.editFamilleLibelle.indexOf(origine.IDFamilleOrigineContact) > -1) { // édition déjà en cours
      return null;
    }

    var editFamilleLibelle = [];
    editFamilleLibelle.push(origine.IDFamilleOrigineContact);

    this.setState({
      editFamilleLibelle: editFamilleLibelle,
    }, () => {
      this.refs.refEditFamilleOrigineLibelle.focus();
    })
  }

  _handleDoubleClickItemSousFamille(origine, event) {

    if (this.state.editSousFamilleLibelle.indexOf(origine.IDSousFamilleOrigineAction) > -1) { // édition déjà en cours
      return null;
    }

    var editSousFamilleLibelle = [];
    editSousFamilleLibelle.push(origine.IDSousFamilleOrigineAction);

    this.setState({
      editSousFamilleLibelle: editSousFamilleLibelle,
    }, () => {
      this.refs.refEditSousFamilleOrigineLibelle.focus();
    })
  }

  onChangeLibelle(origine, event) {
    var editOrigineLibelleContent = this.state.editOrigineLibelleContent;

    editOrigineLibelleContent[origine.IDOrigineAction] = event.target.value;

    this.setState({
      editOrigineLibelleContent: editOrigineLibelleContent,
    })
  }

  onChangeFamilleLibelle(origine, event) {
    var editFamilleLibelleContent = this.state.editFamilleLibelleContent;

    editFamilleLibelleContent[origine.IDFamilleOrigineContact] = event.target.value;

    this.setState({
      editFamilleLibelleContent: editFamilleLibelleContent,
    })
  }

  onChangeSousFamilleLibelle(origine, event) {
    var editSousFamilleLibelleContent = this.state.editSousFamilleLibelleContent;

    editSousFamilleLibelleContent[origine.IDSousFamilleOrigineAction] = event.target.value;

    this.setState({
      editSousFamilleLibelleContent: editSousFamilleLibelleContent,
    })
  }

  _handleKeyPressLibelle(origine, event){
    if (event.key === 'Enter') {
      this.saveLibelle(origine)
    }
  }

  _handleKeyDownLibelle(origine, event) {
    if (event.keyCode === 27) {
      this.setState({
        editOrigineLibelle: [],
        editOrigineLibelleContent: [],
      })
    }
  }

  _handleKeyPressFamilleLibelle(origine, event){
    if (event.key === 'Enter') {
      this.saveFamilleLibelle(origine)
    }
  }

  _handleKeyDownFamilleLibelle(origine, event) {
    if (event.keyCode === 27) {
      this.setState({
        editFamilleLibelle: [],
        editFamilleLibelleContent: [],
      })
    }
  }

  _handleKeyPressSousFamilleLibelle(origine, event){
    if (event.key === 'Enter') {
      this.saveSousFamilleLibelle(origine)
    }
  }

  _handleKeyDownSousFamilleLibelle(origine, event) {
    if (event.keyCode === 27) {
      this.setState({
        editSousFamilleLibelle: [],
        editSousFamilleLibelleContent: [],
      })
    }
  }

  saveLibelle(origine) {

    origine.Libelle = this.state.editOrigineLibelleContent[origine.IDOrigineAction] ? this.state.editOrigineLibelleContent[origine.IDOrigineAction] : origine.Libelle;

    this.setState({
      editOrigineLibelle: [],
      editOrigineLibelleContent: [],
    })

    var updateInProgress = this.state.updateInProgress;
    updateInProgress.push(origine.IDOrigineAction);

    this.setState({
      updateInProgress: updateInProgress,
    })

    fetch(localStorage.backoffice_api + '/api/origines/'+origine.IDOrigineAction, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(origine)
    })
    .then((response) => {

      var updateInProgress = this.state.updateInProgress;
      lodash.remove(this.state.updateInProgress, (id) => {
        return id === origine.IDOrigineAction;
      });

      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });

        this.setState({
          updateInProgress: updateInProgress,
        })

        throw Error(response);
      }

      var indexOrigine = lodash.findIndex(this.state.origines, (o) => {
        return o.IDOrigineAction === origine.IDOrigineAction;
      })

      var origines = this.state.origines;
      origines[indexOrigine] = origine;

      this.setState({
        origines: origines,
        updateInProgress: updateInProgress,
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  saveFamilleLibelle(origine) {
    origine.Famille_Libelle = this.state.editFamilleLibelleContent[origine.IDFamilleOrigineContact] ? this.state.editFamilleLibelleContent[origine.IDFamilleOrigineContact] : origine.Famille_Libelle;
    var famille = {
      Libelle: origine.Famille_Libelle
    };

    this.setState({
      editFamilleLibelle: [],
      editFamilleLibelleContent: [],
    })

    var updateInProgress = this.state.updateInProgress;
    updateInProgress.push(origine.IDOrigineAction);

    this.setState({
      updateInProgress: updateInProgress,
    })

    fetch(localStorage.backoffice_api + '/api/familles/'+origine.IDFamilleOrigineContact, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(famille)
    })
    .then((response) => {

      var updateInProgress = this.state.updateInProgress;
      lodash.remove(this.state.updateInProgress, (id) => {
        return id === origine.IDOrigineAction;
      });

      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });

        this.setState({
          updateInProgress: updateInProgress,
        })

        throw Error(response);
      }

      var indexOrigine = lodash.findIndex(this.state.origines, (o) => {
        return o.IDOrigineAction === origine.IDOrigineAction;
      })

      var origines = this.state.origines;
      origines[indexOrigine] = origine;

      this.setState({
        origines: origines,
        updateInProgress: updateInProgress,
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  saveSousFamilleLibelle(origine) {
    origine.SousFamille_Libelle = this.state.editSousFamilleLibelleContent[origine.IDSousFamilleOrigineAction] ? this.state.editSousFamilleLibelleContent[origine.IDSousFamilleOrigineAction] : origine.SousFamille_Libelle;
    var sousFamille = {
      Libelle: origine.SousFamille_Libelle
    };

    this.setState({
      editSousFamilleLibelle: [],
      editSousFamilleLibelleContent: [],
    })

    var updateInProgress = this.state.updateInProgress;
    updateInProgress.push(origine.IDOrigineAction);

    this.setState({
      updateInProgress: updateInProgress,
    })

    fetch(localStorage.backoffice_api + '/api/sousFamilles/'+origine.IDSousFamilleOrigineAction, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(sousFamille)
    })
    .then((response) => {

      var updateInProgress = this.state.updateInProgress;
      lodash.remove(this.state.updateInProgress, (id) => {
        return id === origine.IDOrigineAction;
      });

      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });

        this.setState({
          updateInProgress: updateInProgress,
        })

        throw Error(response);
      }

      var indexOrigine = lodash.findIndex(this.state.origines, (o) => {
        return o.IDOrigineAction === origine.IDOrigineAction;
      })

      var origines = this.state.origines;
      origines[indexOrigine] = origine;

      this.setState({
        origines: origines,
        updateInProgress: updateInProgress,
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  removeDuplicates() {

    alert('Fonctionnalité désactivée pour le moment !');
    return;

    if (!window.confirm('Êtes-vous sûr de vouloir supprimer définitivement les origines dupliquées ?'))
      return;

    fetch(localStorage.backoffice_api + '/api/origines/removeduplicate', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((json) => {
          alert(json.message)
        })
        return;
      }

      this.setState({
        origines: [],
        familles: [],
      }, () => {
        this.fetchOrigines();
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  openModalDeleteOrigine(origine){
    this.setState({
      origineDelete: origine
    },
      () => window.$('#modalDeleteOrigine').modal('show')
    );
  }

  setFiltre(filtre, value){
    var filtres = this.state.filtres;
    if(value === '')
      delete filtres[filtre];
    else
      filtres[filtre] = value;
    this.setState({
      filtres:filtres
    }, () => {
      this.applyFiltres()
    });
  }

  applyFiltres(){
    var origines = this.state.allOrigines;
    var filtres = this.state.filtres;
    for(var f in filtres){
      switch(f){
        case 'famille':
          origines = lodash.filter(origines, function(o){
            return o.IDFamilleOrigineContact === parseInt(filtres[f],10);
          });
        break;
        case 'sousFamille':
          origines = lodash.filter(origines, function(o){
            return o.IDSousFamilleOrigineAction === parseInt(filtres[f],10);
          });
        break;
        case 'origine':
          origines = lodash.filter(origines, function(o){
            return o.Libelle.toLowerCase().indexOf(filtres[f].toLowerCase()) !== -1;
          });
        break;
      }
    }
    this.setState({
      origines: origines
    }, () => this.generateTooltips());
  }

  generateTooltips() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  checkWorkflowsLeadsAccess(event) {
    if(!this.state.gpParams || !this.state.gpParams.GRP_BDCTraitementLeadAmontChefVente) {
      alert("L'option GRP_BDCTraitementLeadAmontChefVente doit être activée pour pouvoir configurer des workflows");
      event.preventDefault();
      return false;
    }
    else {
      return true;
    }
  }

  render() {

    var isFirstFamille = true;
    var lastFamille;

    var isFirstSousFamille = true;
    var lastSousFamille;

    return (
      <div className='container-fluid'>
        <ModalAddOrigine ref="modalAddOrigine"/>
        <ModalDeleteOrigine origine={this.state.origineDelete}/>
        <ModalTransferOrigine origines={this.state.allOrigines}/>
        <div className="row">
          <div className="col">

            <h2>Origines</h2>

            <div className="row mb-2 mt-4">
              <div className="col">
                <button type='button' className='btn btn-light' data-toggle="modal" data-target="#modalAddOrigine" onClick={() => this.refs.modalAddOrigine.reset()}>
                  <i className='fa fa-plus'></i> Ajouter une origine
                </button>
                <button type='button' className='btn btn-light ml-2' data-toggle="modal" data-target="#modalTransferOrigine">
                  <i className='fa fa-arrow-right'></i> Transférer une origine
                </button>
                <a className='btn btn-light ml-2' onClick={this.checkWorkflowsLeadsAccess.bind(this)} href='/workflowsLeads'>
                  <i className="fa fa-random"></i> Workflows
                </a>
                <a className="btn btn-light ml-2" href="/originesDefaut">
                  <i className="fa-solid fa-arrow-up-right-from-square"></i> Origines par défaut
                </a>
                {this.state.csvData !== null ?
                    <CSVLink data={this.state.csvData} filename={"origines_"+Utils.fromJWT('serverName') +'-'+ Utils.fromJWT('BDGp')+".csv"}  className='btn btn-light ml-2' target="_self" separator={";"}>
                    <i className="fa fa-download" aria-hidden="true"></i> Télécharger (csv)
                  </CSVLink>
                  :null
                }
              </div>
            </div>

            {/*<div className='text-right'>
              <button className='btn btn-danger btn-sm' onClick={this.removeDuplicates.bind(this)}>
                <i className='fa fa-trash'></i> Supprimer les origines dupliquées
              </button>
              </div>*/}

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{width:'15%'}}>Famille
                    <div style={{lineHeight:'1em'}}>
                      <select className="form-control form-control-sm w-auto" value={this.state.filtres.famille || ''} onChange={(e) => this.setFiltre("famille", e.target.value)}>
                        <option value=''>Toutes</option>
                        {this.state.familles.map((f)=>{
                          return <option key={"famille"+f.IDFamilleOrigineContact} value={f.IDFamilleOrigineContact}>{f.Libelle}</option>
                        })}
                      </select>
                    </div>
                  </th>
                  <th style={{width:'15%',whiteSpace:'nowrap'}}>Sous-Famille
                    <div style={{lineHeight:'1em'}}>
                      <select className="form-control form-control-sm w-auto" value={this.state.filtres.sousFamille || ''} onChange={(e) => this.setFiltre("sousFamille", e.target.value)}>
                        <option value=''>Toutes</option>
                        {this.state.sousFamilles.map((sf)=>{
                          return <option key={"sfamille"+sf.IDSousFamilleOrigineAction} value={sf.IDSousFamilleOrigineAction}>{sf.Libelle}</option>
                        })}
                      </select>
                    </div>
                  </th>
                  <th style={{width:'30%'}}>
                    Origine
                    <div>
                      <input type="text" className="form-control" placeholder="Rechercher" value={this.state.filtres.origine || ''} style={{lineHeight:'1em',maxWidth:'300px'}} onChange={(e) => this.setFiltre("origine", e.target.value)}/>
                    </div>
                  </th>
                  <th className='text-center' style={{width:'7%'}}>Type</th>
                  <th className='text-center' style={{width:'7%'}}>Lead</th>
                  <th className='text-center' style={{width:'7%'}}>Inactif</th>
                  <th className='text-center' style={{width:'7%'}}>Appel Sortant</th>
                  <th className='text-center' style={{width:'7%'}}>Appel Entrant</th>
                  <th className='text-center' style={{width:'7%'}}>Création Lead
                    <i className="fa fa-question-circle ml-2" style={{ color: "#9A0059" }} data-toggle="tooltip" data-trigger="hover" data-placement="top" data-html="true"
													title="Si coché alors l'origine est disponible lors de la création d'un lead">
										</i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.origines.map((origine) => {

                  if (!lastFamille || origine.IDFamilleOrigineContact !== lastFamille) {
                    lastFamille = origine.IDFamilleOrigineContact;
                    isFirstFamille = true;
                  }

                  if (!lastSousFamille || origine.IDSousFamilleOrigineAction !== lastSousFamille) {
                    lastSousFamille = origine.IDSousFamilleOrigineAction;
                    isFirstSousFamille = true;
                  }

                  var content = <tr key={origine.IDOrigineAction}>
                    {isFirstFamille ?
                      <td rowSpan={this.getCountSousFamille(origine.IDFamilleOrigineContact)} style={{ verticalAlign: 'middle', }} onDoubleClick={this._handleDoubleClickItemFamille.bind(this, origine)}>
                        {this.state.updateInProgress.indexOf(origine.IDOrigineAction) === -1 ?
                          <div>
                            {this.state.editFamilleLibelle.indexOf(origine.IDFamilleOrigineContact) === -1 ?
                              <span>{origine.Famille_Libelle}</span>
                            :
                              <div>
                                <input type='text' onChange={this.onChangeFamilleLibelle.bind(this, origine)} onKeyPress={this._handleKeyPressFamilleLibelle.bind(this, origine)} onKeyDown={this._handleKeyDownFamilleLibelle.bind(this, origine)} value={this.state.editFamilleLibelleContent[origine.IDFamilleOrigineContact] ? this.state.editFamilleLibelleContent[origine.IDFamilleOrigineContact] : origine.Famille_Libelle} ref='refEditFamilleOrigineLibelle' className='form-control' />
                              </div>
                            }
                          </div>
                        : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                      </td>
                    : null}
                    {isFirstSousFamille ?
                      <td rowSpan={this.getCountOrigine(origine.IDSousFamilleOrigineAction)} style={{ verticalAlign: 'middle', }} onDoubleClick={this._handleDoubleClickItemSousFamille.bind(this, origine)}>
                        {this.state.updateInProgress.indexOf(origine.IDOrigineAction) === -1 ?
                          <div>
                            {this.state.editSousFamilleLibelle.indexOf(origine.IDSousFamilleOrigineAction) === -1 ?
                              <span>{origine.SousFamille_Libelle}</span>
                            :
                              <div>
                                <input type='text' onChange={this.onChangeSousFamilleLibelle.bind(this, origine)} onKeyPress={this._handleKeyPressSousFamilleLibelle.bind(this, origine)} onKeyDown={this._handleKeyDownSousFamilleLibelle.bind(this, origine)} value={this.state.editSousFamilleLibelleContent[origine.IDSousFamilleOrigineAction] ? this.state.editSousFamilleLibelleContent[origine.IDSousFamilleOrigineAction] : origine.SousFamille_Libelle} ref='refEditSousFamilleOrigineLibelle' className='form-control' />
                              </div>
                            }
                          </div>
                        : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                      </td>
                    : null}
                    <td style={{ verticalAlign: 'middle', }} onDoubleClick={this._handleDoubleClickItem.bind(this, origine)}>
                      {this.state.updateInProgress.indexOf(origine.IDOrigineAction) === -1 ?
                        <div>
                          {this.state.editOrigineLibelle.indexOf(origine.IDOrigineAction) === -1 ?
                            <div>
                              {origine.Libelle}
                              <span className="float-right">
                                <span data-toggle="tooltip" data-trigger="hover" data-placement="top" title="Dupliquer">
                                  <button type='button' className='btn btn-sm btn-light' style={{lineHeight:'1em'}} data-toggle="modal" data-target="#modalAddOrigine" onClick={() => this.refs.modalAddOrigine.preFill(origine)}>
                                    <i className="fa fa-clone"></i>
                                  </button>
                                </span>
                                {this.deleteEnabled ?
                                <span data-toggle="tooltip" data-trigger="hover" data-placement="top" title="Supprimer" className="ml-1">
                                  <button type='button' className='btn btn-sm btn-light' style={{lineHeight:'1em'}} onClick={() => this.openModalDeleteOrigine(origine)}>
                                    <i className="fa fa-times"></i>
                                  </button>
                                </span>
                                : null}
                              </span>
                            </div>
                          :
                            <div>
                              <input type='text' onChange={this.onChangeLibelle.bind(this, origine)} onKeyPress={this._handleKeyPressLibelle.bind(this, origine)} onKeyDown={this._handleKeyDownLibelle.bind(this, origine)} value={this.state.editOrigineLibelleContent[origine.IDOrigineAction] ? this.state.editOrigineLibelleContent[origine.IDOrigineAction] : origine.Libelle} ref='refEditOrigineLibelle' className='form-control' />
                            </div>
                          }
                        </div>
                      : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                    </td>
                    <td className='text-center'>
                      {origine.Type_Description ? origine.Type_Description : 'Aucun'}
                    </td>
                    <td className='text-center'>
                      {this.state.updateInProgress.indexOf(origine.IDOrigineAction) === -1 ?
                        <input type='checkbox' onChange={this.onChangeCheckbox.bind(this, origine)} checked={origine.EstLead} name='EstLead' />
                      : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                    </td>
                    <td className='text-center'>
                      {this.state.updateInProgress.indexOf(origine.IDOrigineAction) === -1 ?
                        <input type='checkbox' onChange={this.onChangeCheckbox.bind(this, origine)} checked={origine.EstInactif} name='EstInactif' />
                      : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                    </td>
                    <td className='text-center'>
                      {this.state.updateInProgress.indexOf(origine.IDOrigineAction) === -1 ?
                        <input type='checkbox' onChange={this.onChangeCheckbox.bind(this, origine)} checked={origine.EstAppelSortant} name='EstAppelSortant' />
                      : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                    </td>
                    <td className='text-center'>
                      {this.state.updateInProgress.indexOf(origine.IDOrigineAction) === -1 ?
                        <input type='checkbox' onChange={this.onChangeCheckbox.bind(this, origine)} checked={origine.EstAppelEntrant} name='EstAppelEntrant' />
                      : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                    </td>
                    <td className='text-center'>
                      {this.state.updateInProgress.indexOf(origine.IDOrigineAction) === -1 ?
                        <input type='checkbox' onChange={this.onChangeCheckbox.bind(this, origine)} checked={origine.CreationLead} name='CreationLead' />
                      : <i className="fa fa-circle-o-notch fa-spin fa-fw" aria-hidden="true"></i>}
                    </td>
                  </tr>;

                  if (origine.IDFamilleOrigineContact === lastFamille) {
                    isFirstFamille = false;
                  }

                  if (origine.IDSousFamilleOrigineAction === lastSousFamille) {
                    isFirstSousFamille = false;
                  }

                  return content;
              })}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    );
  }
}

export default List;
